<template>
    <div>
        <div style="background-color: #ffffff;">
            <div class="pageCenter titleBar" :style="{width: pageWidth+'px'}">
                <div class="titleBar_left">
                    <img @click="$oucy.replace('/', null)" style="height: 40px;cursor: pointer;" src="@/assets/logo.png">
                    <div class="titleBar_left_txt">
                        企业管理中心
                    </div>
                </div>
                <div class="titleBar_right" @click="$oucy.replace('/')">
                    返回首页
                </div>
            </div>
        </div>
        <div class="pageCenter breadcrumbBar" :style="{width: pageWidth+'px'}">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <!-- <el-breadcrumb-item :to="{ path: '/ReleaseCenter' }">发布中心</el-breadcrumb-item> -->
                <el-breadcrumb-item :to="{ path: '/ReleaseCenter' }">买家发布</el-breadcrumb-item>
                <el-breadcrumb-item>支付预缴款</el-breadcrumb-item>
            </el-breadcrumb>
            <el-button icon="el-icon-notebook-2" type="text" @click="$oucy.go('/AfficheList?type=1')">帮助手册</el-button>
        </div>
        <div class="container">
            <div class="pageCenter" :style="{width: pageWidth+'px'}">
                <el-container>
                    <div style="padding: 0 0 50px;width: 100%">
                        <h3>支付预缴款结果</h3>
                        <!-- 有货 -->
                        <div class="content p-t-50 p-b-50">
                            <div class="text-center " style="margin-top:86px">
                                <img src="@/assets/images/stockCar/succeed.png">
                                <div class="finishOrder m-t-30">预缴款支付完成，发布成功!!</div>
                                <div class="f14 m-t-15">您的求购已发布，管理员正在审核！</div>
                                <el-button class="m-t-30" @click="$oucy.replace('/', null)">返回</el-button>
                            </div>
                        </div>
                    </div>
                </el-container>
            </div>
        </div>
    </div>
</template>
<script>
import finish from './finish.js'
export default finish
</script>
<style lang="less" scoped>
@import "./finish.less";
</style>