import oucy from "@/util/oucyUtil";

export default {
    name: "Enter",
    data() {
        return {
            pageWidth: oucy.pageWidth,

            tableData: [{
                date: '2016-05-02',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1518 弄'
            }, {
                date: '2016-05-04',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1517 弄'
            }, {
                date: '2016-05-01',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1519 弄'
            }, {
                date: '2016-05-03',
                name: '王小虎',
                address: '上海市普陀区金沙江路 1516 弄'
            }],
            options: [{
                value: '选项1',
                label: '卧室方案'
            }, {
                value: '选项2',
                label: '卧室方案'
            }, {
                value: '选项3',
                label: '卧室方案'
            }, {
                value: '选项4',
                label: '卧室方案'
            }, {
                value: '选项5',
                label: '卧室方案'
            }],
            value: '选项1',
            checked: 1,
            radio: 1,
            num: 1,
            dialogVisible: '',
            dialogVisible222: '1',
            input: '',
            textarea: '',
        }
    },
    methods: {
        handleChange(value) {
            console.log(value);
        },
        setCurrent(row) {
            this.$refs.singleTable.setCurrentRow(row);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
        }
    }
}